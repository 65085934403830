:root {
  --text: #141414;
}

html, body, #root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.button {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  background: #eee;
  padding: 18px 40px;
  display: inline-flex;
  margin: 10px 0;
  border-radius: 50px;
  text-decoration: none;
  border: 0;
  color: var(--text);
}